import React from 'react';
import styles from './SliderButton.module.scss';

const SliderButton = (props) => {

  const onGoTo = dir => {
    props.slider.current.slider.goTo(dir);}
  const imgSource = (props.direction == "next") ? "./img/fc_icon-arrow-next.svg" :"./img/fc_icon-arrow-prev.svg"


  return(
    <div className={[styles.button, styles[props.direction]].join(' ')} onClick={ () => onGoTo(`${props.direction.substring(0, 4)}`) }>
        <div className={styles.imageContainer}><img src={ imgSource } alt={ props.direction } /></div>
    </div>
  )
};

const SliderControls = (props) => {

  return(
    <div className={'sliderControls'}>
      <SliderButton direction="previous" slider={props.slider} ></SliderButton>
      <SliderButton direction="next" slider={props.slider} ></SliderButton>
    </div>
  )
};

// export {SliderControls};
export default SliderControls;
