import React, { useState } from 'react';

const initialStore = {
  guid: '',
  reference: 'Loading...',
  players: [],
  photos: [],
  actionReplays: [],
  loopcams: [],
  games: [],
  preview: []
};

export const Context = React.createContext();

const Store = ({ children }) => {
  const [state, setState] = useState(initialStore);

  return (
    <Context.Provider value={[state, setState]}>{children}</Context.Provider>
  );
}

export default Store;