import React from 'react';
import Store from './Store';
import StoryContainer from './container/StoryContainer';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ReactGA from "react-ga4";


ReactGA.initialize("G-9R3S0492XE");


function App() {
  return (
    <Store className="App">
      <Router>
        <Switch>
          <Route path="/:guid">
            <StoryContainer ></StoryContainer>
          </Route>
        </Switch>
      </Router>
    </Store>
  );
}

export default App;
