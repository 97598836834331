export function animOnSwipe(ts){

  const eleList = {
    scaleEle: ts.getInfo().slideItems,
  }

  const animateEle = (e) => {
    let position = 0,
      i,
      newScale,
      newBoundry;

    if (eleList.scaleEle) {
      for (i = 0; i < eleList.scaleEle.length; i++) {
        position = Math.abs(checkElemPos(eleList.scaleEle[i]));
        newBoundry = 0.9;
        if (position > newBoundry) position = 1;

        if(e.type == 'touchEnd' || e.type == 'dragEnd'){
          if(eleList.scaleEle[i].classList.contains('tns-slide-active')){
            position = 0;
          }else{
            position = 1;
          }
          
        }
        
        newScale = 1 - (position / 5);
        eleList.scaleEle[i].style.transform = "scale(" + newScale + ")";
      }
    }
  }

  const checkElemPos = (element) => {
    //Getting top bottom and center of the viewport
    const docStart = 0;
    const docEnd = window.innerWidth
    const docCenter = (docEnd / 2);
    //Get top and bottom position of element from the top of viewport
    const elemStart = element.getBoundingClientRect().left;
    const elemEnd = elemStart + element.clientWidth;
    //Getting center of element in relation to the center of the viewport (if the element is center screen this will be 0)
    const elemCenter = elemStart + (element.clientWidth / 2) - docCenter;
    //set Default percentage to 1 (off the bottom)
    let perc = 1;
    // If part of the element is in the viewport update the percentage (-1 == off the top, 0 == in center, 1 == off the bottom)
    if (elemStart < docEnd && elemEnd > docStart) {
      //Set percent to the center of the element to the center of the screen plus half its self so anim triggers as soon as element is visible.
      perc = (elemCenter / (docCenter + (element.clientWidth / 2)));
    } else if (elemEnd < docStart) {
      // if not on page but possition is off the top set perc to -1
      perc = -1;
    }
    return perc;
  }

  const throttle = (callback, limit) => {
    
    var tick = false;
    return (...args) => {
      if (!tick) {
        callback(...args);
        tick = true;
        setTimeout(() => {
          tick = false;
        }, limit);
      }
    }
  }
  
  if(ts.getInfo().items == 1){
    ts.events.on('touchMove', throttle(animateEle, 80))
    ts.events.on('dragMove', throttle(animateEle, 80))
    ts.events.on('touchEnd', throttle(animateEle, 80))
    ts.events.on('dragEnd', throttle(animateEle, 80))
    animateEle({event:{type:'load'}});
  }
  

}

