import React, { useRef, useState, useEffect } from 'react';
import styles from './Header.module.scss';
import Vimeo from '@vimeo/player';
import { Context } from '../Store';
import ReactPlayer from 'react-player/lazy'



const Header = (props) => {

  const [imgSrc, setImgSrc] = useState('./img/fc_stories_header_placeholder.webp');

  const state = React.useContext(Context); 

  let photo =  './img/fc_stories_header_'+state.venue.toLowerCase()+'.webp';

  useEffect(() => {
    const img = new Image();
    img.src = photo;
    img.onload = () => {
      setImgSrc(photo);
    };
  }, [photo]);


  let ShowBanner = () => {
    let display = '';
 
    if(state.type == '1' || state.type == '3'){
      display = (
        <div className={styles.positionlogo}>
          <div className={styles.logo}><img src="./img/tournament_banner.svg" alt="Flight Club Darts" /></div>
          <div className={[styles.venue, styles.tournamentVenue].join(' ')}>- Story -</div>
        </div>
      );
    }else{
      display = (
        <div className={styles.positionlogo}>
          <div className={styles.ribbon}>
            <div className={styles.ribbonLeft} style={{backgroundImage:"url('./img/fc_stories_ribbon_left.svg')"}}></div>
            <div className={styles.ribbonCenter} style={{backgroundImage:"url('./img/fc_stories_ribbon_center.svg')"}}>
            <div className={styles.ribboncopy}>{state.venue} Story</div>
            </div>
            <div className={styles.ribbonRight} style={{backgroundImage:"url('./img/fc_stories_ribbon_right.svg')"}}></div>
            {/* <img className={styles.ribbonLeft} src="./img/fc_stories_ribbon_left.png" alt={'ribbon left'} />
            <img className={styles.ribbonCenter} src="./img/fc_stories_ribbon_center.png" alt={'ribbon center'} />
            <img className={styles.ribbonRight} src="./img/fc_stories_ribbon_right.png" alt={'ribbon right'} /> */}
          </div>
          {/* <div className={styles.ribboncopy}>{state.venue} Story</div> */}
          {/* <div className={styles.venue}>{state.venue} Story</div> */}
        </div>
      )
    }

    return display;

  }

  

  return (
    <>
    { (state.feedbackurl !== '' && state.country === 'uk') &&
      <div className={styles.feedback}>
        <div>
        We'd love to hear your feedback. <a href={state.feedbackurl} target="_blank" >Enter&nbsp;here</a>&nbsp;for&nbsp;a&nbsp;chance&nbsp;to&nbsp;win&nbsp;£250!
        </div>
      </div> 
    }
    { (state.feedbackurl !== '' && state.country === 'us') &&
      <div className={styles.feedback}>
        <div>
        Had a good time? <a href={state.feedbackurl} target="_blank" >Google</a>&nbsp;is dying to hear about it.
        </div>
      </div> 
    }
    
    <div className={styles.header}>

      <div className={styles.photo}>
        <img src={imgSrc} alt={'Welcome to your '+state.venue+' Story'} />
      </div>

        
      <div className={styles.photoFade}></div>
      
      <div className={styles.logoContainer}>
        {/* <div className={styles.logoShadow}><img src="./img/logo_shadow.png" alt="" /></div> */}
        <div className={styles.logo}><img src="./img/fc_stories_ribbon_logo.png" alt="Flight Club Darts" /></div>
        <ShowBanner/>
      </div>
    </div>
    </>
  );

}

export default Header;