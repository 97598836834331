import React, {useEffect, useState} from 'react';
import styles from './PreviewOverlay.module.scss';
import ShareBar from "../share/ShareBar";
import { AzureMP } from 'react-azure-mp';
import ReactGA from "react-ga4";
import { getBoundingClientRect } from '../../scripts/helpers';
import { Context } from '../../Store';
import ReactPlayer from 'react-player/lazy'

const PreviewOverlay = (props) => {
  const state = React.useContext(Context); 
  // const { showCopied, setShowCopied } = us
  const [currentPosition, setCurrentPosition] = useState({top: 0, left: 0});
  const [isActive, setIsActive] = useState(false);
  const [currentPreview, setCurrentPreview] = useState('');

  let trackEvent = (category, action, label = '') => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  }

  useEffect(() => {

    props.mount(setCurrentPreview)
    if(currentPreview){
      let rect = getBoundingClientRect(currentPreview.target);
      let top = rect.top + window.scrollY + (( rect.height) / 2);
      let left = rect.left + (rect.width / 2);
      setCurrentPosition({top: top,
        left: left, 
        width: rect.width,
        height: rect.height
      }) 
      openPreview()
    }else{
      setCurrentPosition({top: 0,
        left: 0, 
        width: 200,
        height: 112.5
      }) 
      setIsActive(false);
    }

    
  }, [props.mount, currentPreview]);

  let trackingTimer;

  const openPreview = () => {
    setIsActive(true);
    if( currentPreview.mediaType == 'VIDEO'){
      trackingTimer = setTimeout(() => {
        trackEvent('Play Video', 'Preview', currentPreview.sectionName + ' ' + currentPreview.videoURL);
      }, 3000)
    }
  }

  const handleLeave = () => {
    props.callbackHandler('');
    setIsActive(false)
    clearTimeout(trackingTimer);
  }

  const clickHandler = () => {
    props.callbackHandler(currentPreview);
  }

  let hidePreview;
  let hoverClasses = [styles.itemHover, styles[currentPreview.mediaType]];
  if(isActive){
    clearTimeout(hidePreview)
    if(currentPreview.groupLength > 2){
      hoverClasses.push(styles.active)
    }else{
      hoverClasses.push(styles.activeStatic)
    }
    
  }else{
    
    hidePreview = setTimeout(()=>{hoverClasses.push(styles.hide)}, 500)
  }

  const VideoPlayer = (video) => {
    video = video.video.currentPreview;

    if(video.mediaProvider == 'Azure'){
      return  <AzureMP
                options={{controls: false, autoplay: true, loop: true, muted:true}}
                src={[{src: video.videoURL, type: "application/vnd.ms-sstr+xml" }]}
              />
    }else if(video.mediaProvider == 'AzureBlob'){
      return <ReactPlayer className='react-player'
                url={video.videoURL}
                muted
                loop
                playing	
                width='100%'
                height='100%'
              />
    }else{
      return <iframe src={'https://player.vimeo.com/video/' + video.mediaURI + '?title=0&background=1&portait=0&byline=0&loop=true&autoplay=1&api=1&playsinline=true&quality=360p&player_id=video' + video.mediaURI} 
                frameBorder="0">
              </iframe>
    }
  }

  return(
    <div className={hoverClasses.join(' ')} style={currentPosition} onMouseLeave={handleLeave} data-type={[currentPreview.mediaType]}>
      
        <div className={styles.contentContainer} onClick={clickHandler}>
        <div className={styles.loading}></div>
        { isActive == true &&
          <div className={styles.fullSize}>  
            
          
            { currentPreview.mediaType == 'VIDEO' &&
              <VideoPlayer video={{currentPreview}}/>

            }
            { currentPreview.mediaType == 'PHOTO' &&
              <img src={state.bloburl + '/groupphotos/' + currentPreview.mediaURI} alt="" />
            }
            { currentPreview.mediaType == 'GAME' &&
              currentPreview.game
            }
          </div>
        }
        </div>
      
      <div className={styles.shareBarContainer}>
        <ShareBar mediaShare={currentPreview} cityurl={state.cityurl} azureurl={state.azureurl} videourl={state.videourl} country={state.country} guid={state.guid}  />
      </div>
      
    </div>
  )
};
export default PreviewOverlay;