import React from 'react';
import ShareButton from "../ShareButton.jsx";


const WhatsAppButton = ({mediaShareURL, shareText, mediaType, gameData, sectionName}) => {
  return (
  <ShareButton
      onClick={ () => window.open('https://api.whatsapp.com/send?text=' + shareText + encodeURI(mediaShareURL)) }
      source="./img/share_icon_whatsapp.svg"
      btnName="WhatsApp"
      mediaType={mediaType}
      gameData={gameData}
      sectionName={sectionName}
  />
  )
};

export default WhatsAppButton;
