import React from 'react';
import styles from './ShareBar.module.scss';
import ReactGA from "react-ga4";

const ShareButton = ({ onClick, source, btnName, mediaType, gameData, sectionName, download }) => {

    if(download)download = download.replace('.ism/manifest', '_1280x720.mp4')

    let trackEvent = (category, action, label = '') => {
      ReactGA.event({
        category: category,
        action: action,
        label: label
      });
    }

    let downloadHandler = async () => {
      let isVideo = download.includes(".mp4");
      await fetch(download)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";
        a.download = isVideo ? 'Flight Club Stories video.mp4' : "Flight Club Stories photo.jpg";
        document.body.appendChild(a);
        a.click();
      })
    }

    let clickHandler = () => { 
        trackEvent('Share', 'Share ' + sectionName, btnName)
        if(mediaType == "GAME"){
            let data = gameData;
            let gameurl = "";
            var xhr = new XMLHttpRequest();
            // xhr.withCredentials = true;

            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    onClick();
                }
            });

            if (window.location.href.indexOf(".us.") != -1){
                gameurl = "https://us.flightclubdarts.com/api/stories-game-tile/stories-game-image.php";
            } else if (window.location.href.indexOf(".au") != -1){
                gameurl = "https://flightclubdarts.com.au/api/stories-game-tile/stories-game-image.php";
            } else {
                gameurl = "https://flightclubdarts.com/api/stories-game-tile/stories-game-image.php";
            }

            xhr.open("POST", gameurl);
            xhr.setRequestHeader("Content-Type", "application/json");
            // xhr.setRequestHeader("Accept", "*/*");
            //xhr.setRequestHeader("Cache-Control", "no-cache");

            xhr.send(data);
        }else(
            onClick()
        )
    }

    const Btn = () => {
        let button;
        if(!download){
          button = 
          <div onClick={ clickHandler } className={[styles.shareButton].join(' ')}>
            <img src={ source } alt={ btnName } />
          </div>
          
        }else{
          button = 
          // <a href={download} download={'Flight club Story item'} className={[styles.shareButton].join(' ')}>
          //   <img src={ source } alt={ btnName } />
          // </a>
          <div onClick={ downloadHandler } className={[styles.shareButton].join(' ')}>
            <img src={ source } alt={ btnName } />
          </div>
        }
        return button;
    
      }
  
      return(
          <Btn/>
      )
    
};

export default ShareButton;
