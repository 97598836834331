import React, {useEffect, useState} from 'react';
import FsLightbox from 'fslightbox-react';
import { Context } from '../../Store';
import CopiedContext from '../../CopiedContext';
import ReactGA from "react-ga4";

const Lightbox = (props) => {
  const state = React.useContext(Context); 
  const { showCopied, setShowCopied } = React.useContext(CopiedContext);

  const [currentLightbox, setCurrentLightbox] = useState('');
  const [isActive, setIsActive] = useState(false);

    
  let trackEvent = (category, action, label = '') => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  }

  props.updateLightbox(setCurrentLightbox)

  useEffect(() => {
    
    if(currentLightbox){
      updateLightbox()
      if(currentLightbox.mediashare[currentLightbox.index].mediaType == 'VIDEO'){
        trackEvent('Expand Item', currentLightbox.section, currentLightbox.mediashare[currentLightbox.index].videoURL);
      }else{
        trackEvent('Expand Item', currentLightbox.section, currentLightbox.mediashare[currentLightbox.index].mediaURI);
      }
    }
  }, [props.updateLightbox, currentLightbox]);

  const updateLightbox = () => {
    setIsActive(!isActive);
  }

  const copiedCallback = () => {
    setShowCopied(true)
  }

  const closeCallback = () => {
    setCurrentLightbox('');
    setIsActive(false)
    
  }

  return(
    <div>
      { currentLightbox != '' &&
        <FsLightbox
          toggler={isActive}
          slide={currentLightbox.index+1}
          sources={currentLightbox.sources}
          mediashare={currentLightbox.mediashare}
          type={currentLightbox.type}
          cityurl={state.cityurl}
          azureurl={state.azureurl}
          videourl={state.videourl}
          country={state.country}
          guid={state.guid}
          loadOnlyCurrentSource={true}
          onCopy={copiedCallback}
          onClose={closeCallback}
        />
      }
    </div>
  )
}
export default Lightbox;