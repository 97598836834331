import React from 'react';
import styles from './ActionReplayPlaceholder.module.scss';

const ActionReplayPlaceholder = (props) => {
  let players = props.players.players;
  let video = props.video.video;
  let Game = () => {
    let player = players.filter(player => {return player.id == video.players})[0];
    let playerPhoto = player.photo ? 'data:image/jpeg;base64, ' + player.photo : player.gender == 'male' ? './img/boy.png' : './img/girl.png' ;

    let bgSrc, gameLogo, fontSize, viewBox;
    switch(video.game) {
      case 'DEMOLITION':
        bgSrc = "./img/game-demolition-colour.jpg"
        gameLogo = "./img/gamelogo-demolition.png"
        fontSize = 'smallText';
        viewBox = '0 0 300 80';
        break;
      case 'SHANGHAI':
        bgSrc = "./img/game-shanghai-colour.jpg"
        gameLogo = "/img/gamelogo-shanghai.png"
        fontSize = 'smallText';
        viewBox = '0 0 300 80';
        break;
      case 'QUACKSHOT':
        bgSrc = "./img/game-quackshot-colour.jpg"
        gameLogo = "/img/gamelogo-quackshot.png"
        fontSize = 'largeText';
        viewBox = '0 0 390 90';
        break;
      case 'KILLER':
        bgSrc = "./img/game-killer-colour.jpg"
        gameLogo = "/img/gamelogo-killer.png"
        fontSize = 'largeText';
        viewBox = '0 0 390 90';
        break;
      case 'SNAKESANDLADDERS':
        bgSrc = "./img/game-snakes-colour.jpg"
        gameLogo = "/img/gamelogo-snakes.png"
        fontSize = 'largeText';
        viewBox = '0 0 390 90';
        break;
      case 'DONKEYDERBY':
        bgSrc = "./img/game-donkey-colour.jpg"
        gameLogo = "/img/gamelogo-donkey.png"
        fontSize = 'smallText';
        viewBox = '0 0 300 80';
        break;

    }
    return (
      <div  className={styles.gamesItem}>
        <div className={styles.gamesTile} style={{backgroundImage: "url("+bgSrc+")"}}>
          <img className={styles.gameBackground} src="./img/Stories-Video-graphics.png" alt="" />
          <div className={styles.gamePlayer}>
            <div className={styles.gamePlayerSizer}>
             <img  src={playerPhoto} alt="" />
            </div>
          </div>
          <div className={styles.gameLogo}>
            <img  src={gameLogo} alt="" />
          </div>
          
          <div className={[styles.gameMessage]}>
            <svg viewBox='0 0 150 45' >
              {/* <text x="50%" y="35%" textAnchor="middle" >{message}</text> */}
              <text className={styles.playerName} x="50%" y="80%" textAnchor="middle">{player.name}</text>
            </svg>            
          </div>
        </div>
      </div>
    )
  };

  return <Game/>;
}

export default ActionReplayPlaceholder;