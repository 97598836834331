import React, {useState} from 'react';
import Footer from '../../components/Footer';
import IsCopiedMessage from '../../components/IsCopiedMessage';
import PreviewOverlay from '../../components/PreviewOverlay/PreviewOverlay';
import Lightbox from '../Lightbox/Lightbox';
import CopiedContext from '../../CopiedContext';


const CopyLinkContainer = (props) => {

  const [showCopied, setShowCopied] = useState();
  const copied = { showCopied, setShowCopied };

  return (
    <div>
      <CopiedContext.Provider value={copied}>
        <Footer/>
        <PreviewOverlay mount={props.mount} callbackHandler={props.callbackHandler} />
        <Lightbox updateLightbox={props.updateLightbox} />
        <IsCopiedMessage />
      </CopiedContext.Provider>
    </div>
  );
};

export default CopyLinkContainer;