import React from 'react';
import ShareButton from "../ShareButton.jsx";


const TwitterButton = ({mediaShareURL, shareText, mediaType, gameData, sectionName}) => (
  <ShareButton
      onClick={ () => window.open('http://twitter.com/intent/tweet?text=' + shareText + ' ' + encodeURI(mediaShareURL))  }
      source="./img/share_icon_twitter.svg"
      btnName="Twitter"
      mediaType={mediaType}
      gameData={gameData}
      sectionName={sectionName}
  />
);

export default TwitterButton;
