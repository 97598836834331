import React from 'react';
import styles from './LoadingStory.module.css';


const LoadingStory = (props) => {

  return (
    <div className={[styles.loadingStory, 'section', 'dark'].join(' ')}>
      <div className={styles.logoContainer}>
        <div className={styles.logoShadow}><img src="./img/logo_shadow.png" alt="" /></div>
        <div className={styles.welcome}>Loading your</div>
        <div className={styles.logo}><img src="./img/fc_logo.png" alt="Flight Club Darts" /></div>
        <div className={styles.venue}> Story</div>
      </div>

      
    </div>
  );

}

export default LoadingStory;