import React, { useState, useRef, useEffect  } from 'react';
import styles from './GroupPhotos.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
import { animOnSwipe } from '../scripts/animation';
import { getBoundingClientRect } from '../scripts/helpers';
import {isMobile} from 'react-device-detect';
import ReactGA from "react-ga4";
import { Context } from '../Store';


const GroupPhotos = (props) => {
  const state = React.useContext(Context); 
  const sliderRef = useRef();

  const settings = {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.sliderContainer + ' .sliderControls',
    mouseDrag: true,
    loop: false,
    items: 1,
    gutter: 0,
    edgePadding: 0,
    responsive: {
      640:{
        items: (state.photos.length < 2) ? state.photos.length : 2 ,
        gutter: 5,
        edgePadding: 30,
      },
      1024:{
        items: (state.photos.length < 3) ? state.photos.length : 3 ,
        gutter: 5,
        edgePadding: 30,
      }
    }
  };

  let groupPhotoSources = state.photos.map(photo => {
    return state.bloburl + '/groupphotos/' + photo.asset_uri;
  }); 
  let groupPhotoMedia = state.photos.map(photo => {
    //console.log(photo)
    return {
      mediaType: 'PHOTO',
      mediaURI: photo.asset_uri,
      downloadURI: state.bloburl + '/groupphotos/' + photo.asset_uri,
      sectionName: 'Group Photo'
    };
  });

  
  useEffect(() => {
    animOnSwipe(sliderRef.current.slider);
  }, [sliderRef]);


  const handleHover = (index, e) => {
    let perviewDetails = {
      section: 'GroupPhoto',
      index: index,
      ...groupPhotoMedia[index],
      target: e.currentTarget.querySelector('img'),
      timeStamp: e.timeStamp,
      groupLength: groupPhotoMedia.length,
      sources: groupPhotoSources,
      mediashare: groupPhotoMedia,
      type:"image"
    };
    props.handleHover(perviewDetails, e.type)
  };

  const openLightbox = (index) => {
    let lightboxDetails = {
      section: 'GroupPhoto',
      index: index, 
      sources: groupPhotoSources,
      mediashare: groupPhotoMedia,
      type:"image"
    }
    props.openLightbox(lightboxDetails);
  }

  let title = (state.type == '1' || state.type == '3') ? 'Photo Gallery' : 'Group Photos';


  return(
    <div className="section scale-slider">
      <h2>{title}</h2>
      <div className={styles.sliderContainer}>

        <SliderControls slider={sliderRef} ></SliderControls>

        <TinySlider settings={settings} ref={sliderRef}>
            {state.photos.map((photo, index) => (
              <div key={index} style={{ position: "relative" }} className={[sliderStyles.slide, styles.groupPhotoItem].join(' ')} onClick={() => openLightbox(index)} onMouseEnter={isMobile ? () => (false) : handleHover.bind(this, index)} onMouseLeave={handleHover.bind(this, index)}>
                  <img src={state.bloburl + '/groupphotos/' + photo.asset_uri} alt="" />
              </div>
            ))}
        </TinySlider> 
      </div>
    </div>
  );



}

export default GroupPhotos;