import React, { useContext } from 'react';
import ShareButton from "../ShareButton.jsx";

const DownloadButton = ({mediaShareURL, shareText, mediaType, gameData, sectionName}) => (
    <ShareButton
        onClick={ function(){return true} }
        source="./img/share_icon_download.svg"
        btnName="Download"
        mediaType={mediaType}
        gameData={gameData}
        sectionName={sectionName}
        download={mediaShareURL}
    />
);

export default DownloadButton;
