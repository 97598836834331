import React, {useState, useRef, useEffect} from 'react';
import styles from './BannerContainer.module.scss';

const BannerContainer = (props) => {
  return(
    <div className={'section'}>
      <div className={styles.BannerContainer}>
       <a href={`https://bookings.designmynight.com/book?venue_id=65a1287ff70575656019c0eb&source=partner&type=66b224b74be2d379eb5726f2`} target="_blank">
        <img className={styles.BannerDesk} src="./img/Complimentary-Shuffleboard.jpg" alt="Electric Shuffle" />
        <img className={styles.BannerMob} src="./img/Complimentary-Shuffleboard-2-1.jpg" alt="Electric Shuffle" />
        </a>
      </div>
    </div>
  )

};


export default BannerContainer;