import React, {useState, useRef, useEffect} from 'react';
import styles from './ActionReplays.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
import { animOnSwipe } from '../scripts/animation';
import {isMobile, isIOS} from 'react-device-detect';

import ActionReplayPlaceholder from '../components/ActionReplayPlaceholder'
import { AzureMP } from 'react-azure-mp';
import { Context } from '../Store';
import ReactPlayer from 'react-player/lazy'


const ActionReplays = (props) => {
  const state = React.useContext(Context); 
  const sliderRef = useRef();

  const settings = {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.sliderContainer + ' .sliderControls',
    mouseDrag: true,
    loop: false,
    items: 1,
    gutter: 5,
    responsive: {
      640:{
        items: (state.actionReplays.length < 2) ? state.actionReplays.length : 2,
        edgePadding: 30,
      },
      1024:{
        items: (state.actionReplays.length < 4) ? state.actionReplays.length : 4 ,
        edgePadding: 30,
      }
    }
  };

  let actionReplaysVideos = state.actionReplays.map(video => {
    if(video.video_provider == 'Azure'){
      return  <div className={styles.actionReplayItem}><AzureMP
                options={{controls: true, autoplay:true, muted:true}}
                src={[{src: video.video_url, type: "application/vnd.ms-sstr+xml" }]}
              />
              </div>
    }else if(video.video_provider == 'AzureBlob'){
    return     <div className={styles.actionReplayItem}>
              <ReactPlayer className='react-player'
                url={video.video_url}
                muted
                controls
                playing	
                width='100%'
                height='100%'
              />
              </div>
    }else{
      return <iframe id={'video' + video.asset_uri} src={'https://player.vimeo.com/video/' + video.asset_uri + '?title=0&portait=0&byline=0&loop=true&api=1&playsinline=true&player_id=video' + video.asset_uri} frameBorder="0"  width="1920px" height="1080px" ></iframe>
    }
   
   }); 
  let actionReplaysMedia = state.actionReplays.map(video => {
    return {
      mediaType: 'VIDEO',
      mediaURI: video.asset_uri,
      mediaProvider: video.video_provider,
      videoURL: video.video_url,
      sectionName: 'Action Replay'
    };
  }); 
  if(state.debug)console.log(actionReplaysMedia);

  useEffect(() => {
    animOnSwipe(sliderRef.current.slider);
  }, [sliderRef]);
  
  const handleHover = (index, e) => {
    let perviewDetails = {
      section: 'ActionReplay',
      index: index,
      ...actionReplaysMedia[index],
      target: e.currentTarget,
      timeStamp: e.timeStamp,
      groupLength: actionReplaysMedia.length,
      sources: actionReplaysVideos,
      mediashare: actionReplaysMedia
    };
    props.handleHover(perviewDetails, e.type)
  };

  const openLightbox = (index) => {
    let lightboxDetails = {
      section: 'ActionReplay',
      index: index, 
      sources: actionReplaysVideos,
      mediashare: actionReplaysMedia
    }

    props.openLightbox(lightboxDetails);
  }
  
  let title = (state.type === '1' || state.type === '3') ? 'Game Highlights' : 'Action Replays';
  
  let players = state.players;
  return(
    <div className="section scale-slider" >
      <h2>{title}</h2>
      <div className={styles.sliderContainer} >

        <SliderControls slider={sliderRef}></SliderControls>
        
        <TinySlider settings={settings} ref={sliderRef} >
            {state.actionReplays.map((video, index) => (
              <div key={index} style={{ position: "relative" }}  className={styles.actionReplayItem} onClick={() => openLightbox(index)} onMouseEnter={isMobile ? () => (false) : handleHover.bind(this, index)} onMouseLeave={handleHover.bind(this, index)}>
                { !video.thumbnail_url &&
                  <ActionReplayPlaceholder video={{video}} players={{players}} />
                }
                { video.thumbnail_url &&
                  <img src={video.thumbnail_url} alt="" />
                }
              </div>
            ))}
        </TinySlider>
    
      </div>
    </div>
  );



}

export default ActionReplays;