import React, {useState,useRef, useEffect} from 'react';
import styles from './Loopcams.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
import { animOnSwipe } from '../scripts/animation';
import {isMobile, isIOS} from 'react-device-detect';
import { AzureMP } from 'react-azure-mp';
import { Context } from '../Store';
import ReactPlayer from 'react-player/lazy'

const Loopcams = (props) => {
  const state = React.useContext(Context); 
  const sliderRef = useRef();


  const settings = {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.sliderContainer + ' .sliderControls',
    mouseDrag: true,
    loop: false,
    items: 1,
    gutter: 5,
    responsive: {
      640:{
        items: (state.loopcams.length < 2) ? state.loopcams.length : 2 ,
        edgePadding: 30,
      },
      1024:{
        items: (state.loopcams.length < 4) ? state.loopcams.length : 4 ,
        edgePadding: 30,
      }
    }
  };

  let showControls = isIOS ? true : false;
  let loopcamVideos = state.loopcams.map(video => {
    if(video.video_provider == 'Azure'){
      return  <div className={styles.loopcamItem}><AzureMP
                options={{controls: showControls, autoplay: true, playsInline: true, muted:true}}
                src={[{src: video.video_url, type: "application/vnd.ms-sstr+xml" }]}
              />
              </div>
  
    }else if(video.video_provider == 'AzureBlob'){
       return  <div className={styles.loopcamItem}>
                <ReactPlayer className='react-player'
                  url={video.video_url}
                  muted
                  controls
                  playing	
                  width='100%'
                  height='100%'
                />
                </div>
    }else{
      return <iframe id={'video' + video.asset_uri} src={'https://player.vimeo.com/video/' + video.asset_uri + '?title=0&portait=0&byline=0&loop=true&api=1&playsinline=true&player_id=video' + video.asset_uri} frameBorder="0"  width="1920px" height="1080px" ></iframe>
    }
  }); 
  let loopcamMedia = state.loopcams.map(video => {
    return {
      mediaType: 'VIDEO',
      mediaURI: video.asset_uri,
      mediaProvider: video.video_provider,
      videoURL: video.video_url,
      sectionName: 'Loop Cams'
    };
  }); 

  useEffect(() => {
    animOnSwipe(sliderRef.current.slider);
  }, [sliderRef]);

  const handleHover = (index, e) => {
    let perviewDetails = {
      section: 'LoopCams',
      index: index,
      ...loopcamMedia[index],
      target: e.currentTarget,
      timeStamp: e.timeStamp,
      groupLength: loopcamMedia.length,
      sources: loopcamVideos,
      mediashare: loopcamMedia
    };
    
    props.handleHover(perviewDetails, e.type)
  };

  const openLightbox = (index) => {
    let lightboxDetails = {
      section: 'LoopCams',
      index: index, 
      sources: loopcamVideos,
      mediashare: loopcamMedia
    }

    props.openLightbox(lightboxDetails);
  }
  
  let title = (state.type == '1' || state.type == '3') ? 'Loopcam Gallery' : 'Loopcams';

  return(
    <div className={[styles.section, 'section', 'scale-slider'].join(' ')}>
      <h2>{title}</h2>
      <div className={styles.sliderContainer}>

        <SliderControls slider={sliderRef}></SliderControls>
      
        <TinySlider settings={settings} ref={sliderRef}>
            {state.loopcams.map((video, index) => (
              <div key={index} style={{ position: "relative" }} className={[sliderStyles.slide, styles.loopcamItem].join(' ')} onClick={() => openLightbox(index)} onMouseEnter={isMobile ? () => (false) : handleHover.bind(this, index)} onMouseLeave={handleHover.bind(this, index)}>
               { !video.thumbnail_url &&
                <img src="./img/Stories-Loop-Cam-graphic.png" alt="" className={styles.iosPlaceholder} />
                }
                { video.thumbnail_url &&
                <img src={video.thumbnail_url} alt="" className={styles.iosPlaceholder} />
                }
              </div>
            ))}
        </TinySlider>
      </div>
      
    </div>
  );
}

export default Loopcams;