import React, {useRef} from 'react';
import styles from './Players.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
import { Context } from '../Store';

const Players = (props) => {
  const state = React.useContext(Context); 

  function getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return (s[(v - 20) % 10] || s[v] || s[0]);
  }


  const sliderRef = useRef(); 
  //console.log(state)
 
  const settings = (state.type == '3') ? {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.playersContainer + ' .sliderControls',
    mouseDrag: true,
    loop: false,
    items: 2,
    slideBy: 2,
    gutter: 5,
    edgePadding: 10,
    responsive: {
      480:{
        items: 3 ,
        slideBy: 3,
      },
      640:{
        items: 4,
        slideBy: 4,
      },
      1024:{
        items: (state.players.length < 6) ? state.players.length : 6 ,
        slideBy: (state.players.length < 6) ? state.players.length : 2,
      }
    }
  } : (state.type == '1') ? {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.playersContainer + ' .sliderControls',
    mouseDrag: true,
    loop: false,
    items: 1,
    gutter: 30,
    edgePadding: 25,
    responsive: {
      480:{
        items: 1 ,
      },
      640:{
        items: 1
      },
      1024:{
        items: 2 ,
      }
    }
  } : {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.playersContainer + ' .sliderControls',
    mouseDrag: true,
    loop: false,
    items: 2,
    gutter: 5,
    edgePadding: 10,
    responsive: {
      480:{
        items: 3 ,
      },
      640:{
        items: 4
      },
      1024:{
        items: (state.players.length < 6) ? state.players.length : 6 ,
      }
    }
  }

  let teams = [];
  if(state.type == '1'){
    teams = [...state.teams].sort((a,b) => b.finalist - a.finalist || a.final_result - b.final_result || b.points - a.points || b.medals_gold - a.medals_gold || b.medals_silver - a.medals_silver).map((team, index) => {
      let teamPlayers = [...state.players].filter(player => {return player.team_id == team.id}).sort((a,b) => b.points - a.points || b.gold - a.gold || b.silver - a.silver).map((player, index) => {
        let playerPhoto = player.photo ? 'data:image/jpeg;base64, ' + player.photo : player.gender == 'male' ? './img/boy.png' : './img/girl.png';
        return (
          <div key={player.id} className={styles.playerItem}>
            <div className={styles.playerImage}>
              <div className={styles.playerImageSizer}>
                <img src={playerPhoto} alt="" />
              </div>
              
            </div>
            <div className={styles.playerName}>
            {player.name.toLowerCase()}
            </div>
          </div>
        )
      })
      if(teamPlayers.length > 0){

      return (

        <div  key={team.id} className={styles.teamItem}>
          <div className={styles.teamTitle}>
            <h3 className={styles.teamName}>{team.name}</h3>
            <div className={styles.points}><div className={styles.pointsHeight}></div>{team.points}</div>
          </div>
          <div className={styles.teamPlayers}>
            {teamPlayers}
          </div>
        </div>
      )
    }else{
      return (false);
    }

    })
  }

  let players = [...state.players].sort((a,b) => b.points - a.points || b.gold - a.gold || b.silver - a.silver).map((player, index) => {
    let playerPhoto = player.photo ? 'data:image/jpeg;base64, ' + player.photo : player.gender == 'male' ? './img/boy.png' : './img/girl.png' ;

    return (
      <div key={player.id} className={styles.playerItem}>
        <div className={styles.playerImage}>
          <div className={styles.playerImageSizer}>
            <img src={playerPhoto} alt="" />
          </div>
          <div className={styles.points}><div className={styles.pointsHeight}></div>{player.points}</div>
        </div>
        <div className={styles.playerName}>
        <span>{index+1}<sup>{getNumberWithOrdinal(index+1)}</sup></span> {player.name.toLowerCase()}
        </div>
      </div>
    )
  });

const Winner = () =>{
  return (
    <div className={styles.sessionWinner}>
      <span>W</span>
      <span>I</span>
      <span>N</span>
      <span>N</span>
      <span>E</span>
      <span>R</span>
      <span>!</span>
    </div>
  )
} 
// let sessionPlayers = [];
// if(state.type == '3'){
//   let playerLength = Math.ceil(players.length / 2)
//   let visPlayers = 6;
//   let counter = 0;
//   let lastSlide = (players.length % (visPlayers * 2)) /2;
//   let shiftPlayers = visPlayers
//   for(let i = 0; i < playerLength; i++){
//     if(i >=  playerLength - lastSlide ){
//       shiftPlayers = lastSlide
//     }
//     let twoPlayers = (
//       <div key={i} className={styles.sessionGroup}>
//         {players[i+counter]}
//         {players[i+shiftPlayers+counter]}
//         {i == 0 && 
//           <Winner/>
//         }
//       </div>
//     )
//     sessionPlayers.push(twoPlayers);
//     let remainder = (i + 1) % visPlayers;
//     if(remainder == 0 ){
//       counter = counter + visPlayers;
//     }
//   }
// }
let sessionPlayers = [];
if(state.type == '3'){
  let playerLength = Math.ceil(players.length / 2)
  let counter = 0;
  for(let i = 0; i < playerLength; i++){
    let twoPlayers = (
      <div key={i} className={styles.sessionGroup}>
        {players[counter]}
        {players[counter+1]}
        {i == 0 && 
          <Winner/>
        }
      </div>
    )
    sessionPlayers.push(twoPlayers);
    counter = counter + 2;
  }
}



  let playerView = (state.type == '1') ? teams : (state.type == '3') ? sessionPlayers : players;
  let stylesSection = (state.type == '1') ? 'teams_section' : (state.type == '3') ? 'session_section' : 'players_section';

  return (
    <div className={`section ${stylesSection}`}>
      <h2>PLAYERS</h2>
      <div className="">
        <div className={styles.playersContainer}>
          <SliderControls slider={sliderRef} ></SliderControls>
          
          <TinySlider settings={settings} ref={sliderRef}>
              {playerView}
          </TinySlider>

        </div>
      </div>
    </div>
  )
}

export default Players;