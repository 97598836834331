import React, { useContext, useEffect } from 'react';
import styles from './IsCopiedMessage.module.scss';
import CopiedContext from '../CopiedContext';


const IsCopiedMessage = () => {
  const { showCopied, setShowCopied } = useContext(CopiedContext);
  //[sliderStyles.slide, styles.groupPhotoItem].join(' ')
  let Classes = [styles.isCopiedContainer]
  useEffect(() => {
    if(showCopied){
      
      setTimeout(function(){setShowCopied(false)}, 4000);
    }
  })

  if(showCopied){
    Classes.push(styles.active);
  }else{
    Classes = [styles.isCopiedContainer]
  }

  return (
    <div>
        <div className={Classes.join(' ')}>
          Copied to clipboard!
        </div>
    </div>
  );
}

export default IsCopiedMessage;