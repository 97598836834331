import React, { useState, useRef, useEffect } from 'react';
import styles from './Games.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
import { animOnSwipe } from '../scripts/animation';
import {isMobile} from 'react-device-detect';
import { Context } from '../Store';


const Games = (props) => {
  const state = React.useContext(Context); 
  
  const handleHover = (index, e) => {
    if(e.currentTarget.classList.contains('tns-item')){
      let perviewDetails = {
        section: 'Games',
        index: index,
        ...gamesMedia[index],
        target: e.currentTarget.querySelector('img'),
        game:games[index],
        timeStamp: e.timeStamp,
        groupLength: games.length,
        sources: games,
        mediashare: gamesMedia,
        type:"image"
      };

      props.handleHover(perviewDetails, e.type)
    }
  };

  let games = state.games.filter((game) => {
    let player = state.players.filter(player => {return player.id == game.players.split(",")[0]})[0];
    return player;
  })
  .map((game, index) => {
    let player = state.players.filter(player => {return player.id == game.players.split(",")[0]})[0];

    let playerPhoto = player.photo ? 'data:image/jpeg;base64, ' + player.photo : player.gender == 'male' ? './img/boy.png' : './img/girl.png' ;

    let bgSrc, message;
    switch(game.game) {
      case 'DEMOLITION':
        bgSrc = "./img/game-demolition-bg.jpg"
        message = "Highest 3 dart score"
        break;
      case 'SHANGHAI':
        bgSrc = "./img/game-shanghai-bg.jpg"
        message = "Highest 3 dart score"
        break;
      case 'QUACKSHOT':
        bgSrc = "./img/game-quackshot-bg.jpg"
        message = "Most Ducks"
        break;
      case 'KILLER':
        bgSrc = "./img/game-killer-bg.jpg"
        message = "Most Kills"
        break;
      case 'SNAKESANDLADDERS':
        bgSrc = "./img/game-snakes-bg.jpg"
        message = "Most snakes"
        break;
      case 'DONKEYDERBY':
        bgSrc = "./img/game-donkey-bg.jpg"
        message = "Highest number of furlongs in 3 darts"
        break;
      default:
        break;

    }
    return (
      <div key={game.id} id={'game_' + game.id} className={styles.gamesItem} onClick={() => openLightbox(index)} onMouseEnter={isMobile ? () => (false) : handleHover.bind(this, index)} onMouseLeave={handleHover.bind(this, index)}>
        <div className={styles.gamesTile}>
          <img className={styles.gameBackground} src={bgSrc} alt="" />
          <div className={styles.gamePlayerSizer}>
            <img className={styles.gamePlayer} src={playerPhoto} alt="" />
          </div>
          <div className={[styles.gameMessage]}>
            <svg viewBox='0 0 300 90' >
              <text x="50%" y="35%" textAnchor="middle" >{message}</text>
              <text className={styles.playerName} x="50%" y="80%" textAnchor="middle">{player.name}</text>
            </svg>            
          </div>
        </div>
      </div>
    )
  });




  let gamesMedia = state.games.filter((game) => {
    let player = state.players.filter(player => {return player.id == game.players.split(",")[0]})[0];
    return player;
  }).map(game => {
    let player = state.players.filter(player => {return player.id == game.players.split(",")[0]})[0];
    let message;
    switch(game.game) {
      case 'DEMOLITION':
        message = "Highest 3 dart score"
        break;
      case 'SHANGHAI':
        message = "Highest 3 dart score"
        break;
      case 'QUACKSHOT':
        message = "Most Ducks"
        break;
      case 'KILLER':
        message = "Most Kills"
        break;
      case 'SNAKESANDLADDERS':
        message = "Most snakes"
        break;
      case 'DONKEYDERBY':
        message = "Highest number of furlongs in 3 darts"
        break;
      default:
        break;
    }
    let gameData = JSON.stringify({
      guid:state.guid,
      gametype:game.game,
      message:message,
      playername:player.name,
      playerphoto:player.photo,
      playergender: player.gender

    })

    return {
      mediaType: 'GAME',
      mediaURI: state.guid + '_' + game.game,
      gameData: gameData,
      sectionName: 'Games'
    };
  }); 

  const sliderRef = useRef();

  const settings = {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.sliderContainer + ' .sliderControls',
    mouseDrag: true,
    loop: false,
    items: 1,
    gutter: 5,
    responsive: {
      640:{
        items: (games.length < 2) ? games.length : 2 ,
        edgePadding: 30,
      },
      1024:{
        items: (games.length < 4) ? games.length : 4 ,
        edgePadding: 30,
      }
    }
  };

  const openLightbox = (index) => {
    let lightboxDetails = {
      section: 'Games',
      index: index, 
      sources: games,
      mediashare: gamesMedia,
      type:"image"
    }

    props.openLightbox(lightboxDetails);
  }

  useEffect(() => {
    if(games.length > 0){
      animOnSwipe(sliderRef.current.slider);
    }
  });


  let title = (state.type == '1' || state.type == '3') ? 'Tournament Highlights' : 'Games';
  if(games.length > 0){
  return(
    <div className="section scale-slider games_section">
      <h2>{title}</h2>

      <div className={styles.sliderContainer}>
        <SliderControls slider={sliderRef}></SliderControls>
        
        <TinySlider settings={settings} ref={sliderRef}>
          {games}
        </TinySlider> 
      </div>
    </div>
  );

  }
  else{
    return null;
  }

}

export default Games;